import axios from "axios";

const useBudgetPetsTabFormPublicService = () => {
    const getPetsQuoteQuantity = async () => {
        try {
            let service = "/dbo/animals/quote_quantity";
            let response = await axios.post(service); 
            if(response.status === 200 && response.data.result.length !== 0) {
                return response.data.result[0].CODLVAL;
            }
            else {
                return "error";
            }
        }
        catch(error) {
            console.log(error);
            return "error";
        }
    };
    return {
        getPetsQuoteQuantity
    };
};

export default useBudgetPetsTabFormPublicService;
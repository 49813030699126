import {
    container
  } from "components/material-kit-pro-react/material-kit-pro-react";

const parallaxViewStyle = {
    container,
    parallax: {
        // height: "90vh",
        alignItems: "flex-start",
        paddingTop: "120px",
        height: "850px",
        overflow: "hidden",
        "@media (max-width: 415px)": {
          height: "750px"
        },
        "@media (max-width: 375px)": {
          height: "750px"
        },
        "@media (max-width: 325px)": {
          height: "750px"
        }
        
    }
};

export default parallaxViewStyle;

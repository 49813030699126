import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { v4 as uuid } from "uuid"

import CircularProgress from "@mui/material/CircularProgress"

import PetsIcon from "@mui/icons-material/Pets"
import SendIcon from "@mui/icons-material/Send"
import BudgetErrorPetsIcon from "../../../../icons/BudgetErrorPetsIcon/BudgetErrorPetsIcon"

import InputController from "components/Core/Controller/InputController"
import PhoneController from "components/Core/Controller/PhoneController"
import EmailController from "components/Core/Controller/EmailController"
import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button.js"

import BudgetPetInputPublic from "../BudgetPetInputPublic/BudgetPetInputPublic"
import { useDialog } from "context/DialogContext"
import useBudgetPetsTabFormPublicService from "../../services/useBudgetPetsTabFormPublicService"

import styles from "./styles/BudgetPetsTabFormStylesPublic"
import "./styles/BudgetPetsTabFormPublic.scss"

const BudgetPetsTabFormPublic = (props) => {
  const { onGenerate, minPetAgeAllowed, maxPetAgeAllowed } = props
  const [preload, setPreload] = useState(false)
  const [maxPetsAllowedValid, setMaxPetsAllowedValid] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showError, setShowError] = useState(false)
  const [maxPetsAllowed, setMaxPetsAllowed] = useState(1)
  const [petArray, setPetArray] = useState([
    {
      index: uuid(),
      petAge: "",
      petTypeAge: "",
      errorPetAge: false,
      errorPetTypeAge: false,
    },
  ])
  const [showPetAddButton, setShowPetAddButton] = useState(true)
  const [disabledButton, setDisabledButton] = useState(false)
  const { handleSubmit, ...objForm } = useForm()
  const dialog = useDialog()
  const { getPetsQuoteQuantity } = useBudgetPetsTabFormPublicService()
  const validPetsFieldController = () => {
    let arrayValidator = []
    petArray.map((pet) => {
      let isPetAgeValid = checkPetAge(pet)
      let isPetTypeAgeValid = checkPetTypeAge(pet)
      arrayValidator = [...arrayValidator, isPetAgeValid, isPetTypeAgeValid]
    })
    let resultValidations = checkArrayValidator(arrayValidator)
    if (resultValidations) {
      return true
    }
    return false
  }
  const validRangeAgeAllowed = () => {
    let errorMessageValidator = ""
    let arrayValidator = []
    petArray.map((pet, index) => {
      if (pet.petTypeAge === "month") {
        let validator1 = ageIsInRange(parseInt(pet.petAge))
        if (!validator1) {
          errorMessageValidator =
            errorMessageValidator +
            `La mascota ${index + 1} no posee una edad valida, `
        }
        arrayValidator = [...arrayValidator, validator1]
      } else if (pet.petTypeAge === "year") {
        let validator2 = ageIsInRange(parseInt(pet.petAge) * 12)
        if (!validator2) {
          errorMessageValidator =
            errorMessageValidator +
            `La mascota ${index + 1} no posee una edad valida, `
        }
        arrayValidator = [...arrayValidator, validator2]
      } else {
        arrayValidator = [...arrayValidator, false]
      }
    })
    let resultValidations = checkArrayValidator(arrayValidator)
    let errorMessageFormatted = errorMessageValidator.trim()
    let errorMessageFormatted2 = errorMessageFormatted.substring(
      0,
      errorMessageFormatted.length - 1
    )
    if (resultValidations) {
      return {
        validation: true,
        errorMessage: errorMessageFormatted2,
      }
    }
    return {
      validation: false,
      errorMessage: errorMessageFormatted2,
    }
  }
  const ageIsInRange = (petAge) => {
    if (minPetAgeAllowed <= petAge && maxPetAgeAllowed >= petAge) {
      return true
    }
    return false
  }
  const checkPetAge = (pet) => {
    if (pet.petAge === "" || pet.petAge === undefined || pet.petAge === null) {
      setErrorPetAge(pet.index, true)
      return false
    } else {
      return true
    }
  }
  const checkPetTypeAge = (pet) => {
    if (
      pet.petTypeAge === "" ||
      pet.petTypeAge === undefined ||
      pet.petTypeAge === null
    ) {
      setErrorPetTypeAge(pet.index, true)
      return false
    } else {
      return true
    }
  }
  const checkArrayValidator = (validations) => {
    if (validations.length !== 0) {
      let resultValidations = validations.filter(
        (validation) => validation === false
      )
      if (resultValidations.length === 0) {
        return true
      }
    }
    return false
  }
  const addPet = () => {
    if (petArray.length < maxPetsAllowed) {
      setPetArray([
        ...petArray,
        {
          index: uuid(),
          petAge: "",
          petTypeAge: "",
          errorPetAge: false,
          errorPetTypeAge: false,
        },
      ])
    }
  }
  const updatePetAge = (petIndex, value) => {
    let newPetArray = petArray.map((pet) => {
      if (pet.index === petIndex) {
        pet.petAge = value
      }
      return pet
    })
    setPetArray(newPetArray)
  }
  const updatePetTypeAge = (petIndex, value) => {
    let newPetArray = petArray.map((pet) => {
      if (pet.index === petIndex) {
        pet.petTypeAge = value
      }
      return pet
    })
    setPetArray(newPetArray)
  }
  const deletePet = (petIndex) => {
    let newPetArray = petArray.filter((pet) => pet.index !== petIndex)
    setPetArray(newPetArray)
  }
  const setErrorPetAge = (petIndex, value) => {
    let newPetArray = petArray.map((pet) => {
      if (pet.index === petIndex) {
        pet.errorPetAge = value
      }
      return pet
    })
    setPetArray(newPetArray)
  }
  const setErrorPetTypeAge = (petIndex, value) => {
    let newPetArray = petArray.map((pet) => {
      if (pet.index === petIndex) {
        pet.errorPetTypeAge = value
      }
      return pet
    })
    setPetArray(newPetArray)
  }
  const addProp = (obj, propName, propValue) => {
    obj[propName] = propValue
  }
  const fetchPetsQuoteQuantity = async () => {
    let response = await getPetsQuoteQuantity()
    if (response === "error") {
      setShowError(true)
      // dialog({
      //     variant: 'info',
      //     catchOnCancel: false,
      //     title: "Alerta",
      //     description: 'No se ha podido obtener el número máximo de mascotas. Ha fallado el servicio /dbo/animals/quote_quantity. Por favor recargue la página e intente nuevamente.'
      // });
    } else {
      setMaxPetsAllowed(parseInt(response))
      setMaxPetsAllowedValid(true)
    }
  }
  const integratingForms = (dataform) => {
    if (petArray.length !== 0) {
      petArray.map((pet, index) => {
        addProp(dataform, `p_pet_${index + 1}_age`, pet.petAge)
        addProp(dataform, `p_pet_${index + 1}_type_age`, pet.petTypeAge)
        return null
      })
    }
  }
  const formatForms = (dataform) => {
    let finalPetData = null
    let petDataForm = {
      ...dataform,
    }
    let petDataJsonString = ""
    delete petDataForm.p_applicant_email
    delete petDataForm.p_applicant_name
    delete petDataForm.p_applicant_phone_number
    delete petDataForm.p_portal_user_id
    let dataformEntry = Object.entries(petDataForm)
    dataformEntry.map((item) => {
      petDataJsonString = petDataJsonString + `,\"${item[0]}\":\"${item[1]}\"`
      return null
    })
    finalPetData = {
      // p_applicant_name: dataform.p_applicant_name,
      // p_applicant_phone_number: dataform.p_applicant_phone_number,
      // p_applicant_email: dataform.p_applicant_email,
      // p_portal_user_id: dataform.p_portal_user_id,
      p_json_info: `{\"p_applicant_email\":\"${dataform.p_applicant_email}\",\"p_applicant_name\":\"${dataform.p_applicant_name}\",\"p_applicant_phone_number\":\"${dataform.p_applicant_phone_number}\",\"p_pet_total\":${petArray.length}${petDataJsonString}}`,
    }
    return finalPetData
  }
  const onSubmit = async (dataform, e) => {
    let formIsValid = validPetsFieldController()
    let { validation, errorMessage } = validRangeAgeAllowed()
    if (formIsValid && validation) {
      integratingForms(dataform)
      onGenerate(formatForms(dataform))
    } else {
      !validation &&
        dialog({
          variant: "info",
          catchOnCancel: false,
          title: "Alerta",
          description: errorMessage,
        })
    }
  }
  useEffect(() => {
    fetchPetsQuoteQuantity()
    setLoading(false)
    setPreload(true)
  }, [])
  useEffect(() => {
    if (preload) {
      petArray.length < maxPetsAllowed
        ? setShowPetAddButton(true)
        : setShowPetAddButton(false)
      petArray.length !== 0 ? setDisabledButton(false) : setDisabledButton(true)
    }
  }, [petArray])
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="budget-pets-tab-form-public"
      >
        {loading && (
          <>
            <div
              style={{
                width: "100%",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          </>
        )}
        {!loading && (
          <div
            style={{
              width: "99%",
            }}
          >
            {maxPetsAllowedValid && (
              <>
                <InputController
                  control={objForm.control}
                  label="Tu nombre"
                  name="p_applicant_name"
                  fullWidth
                  variant="outlined"
                  placeholder="Nombre"
                />
                <PhoneController
                  control={objForm.control}
                  label="Teléfono"
                  name="p_applicant_phone_number"
                  style={{
                    width: "100%",
                  }}
                />
                <EmailController
                  control={objForm.control}
                  label="Email"
                  name="p_applicant_email"
                  style={{
                    width: "100%",
                  }}
                />
                {petArray.map((pet, index) => (
                  <BudgetPetInputPublic
                    key={index}
                    index={pet.index}
                    petNumber={index}
                    petAge={pet.petAge}
                    petTypeAge={pet.petTypeAge}
                    minPetAge={1}
                    maxPetAge={180}
                    errorPetAge={pet.errorPetAge}
                    errorPetTypeAge={pet.errorPetTypeAge}
                    updatePetAge={updatePetAge}
                    updatePetTypeAge={updatePetTypeAge}
                    deletePet={deletePet}
                    setErrorPetAge={setErrorPetAge}
                    setErrorPetTypeAge={setErrorPetTypeAge}
                  />
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "0.85rem",
                    padding: "0.5rem 0 0.3rem 0",
                    fontStyle: "italic",
                    color: "#333333",
                  }}
                >
                  La edad de las mascotas no debe ser menor a 5 meses ni mayor a
                  16 años
                </div>
                {showPetAddButton && (
                  <Button
                    style={{
                      width: "100%",
                      background: "#4caf50",
                    }}
                    className={styles.button}
                    fullWidth
                    onClick={addPet}
                  >
                    <PetsIcon /> Agregar Mascota
                  </Button>
                )}
                <Button
                  color="primary"
                  type="submit"
                  fullWidth
                  className={styles.button}
                  disabled={disabledButton}
                  style={{
                    width: "100%",
                  }}
                >
                  <SendIcon /> Cotizar
                </Button>
              </>
            )}
            {showError && (
              <div
                style={{
                  paddingTop: "2rem",
                  width: "100%",
                  height: "220px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <BudgetErrorPetsIcon />
                <h5 style={{ fontSize: "0.9rem" }}>
                  Servicio temporalmente no disponible. Por favor recargue la
                  página e intente de nuevo.
                </h5>
              </div>
            )}
          </div>
        )}
      </form>
    </>
  )
}

export default BudgetPetsTabFormPublic

import React, { useState , useEffect} from "react"
import Carousel from "react-material-ui-carousel"
import { graphql, useStaticQuery } from "gatsby"
import ImageChinche from "../Noticias/chinche.png"

export default function CarouselNews(props) {
  const [index, setIndex] = useState(0)
  const [isHovered, setIsHovered] = useState(false)
  const {onDataAvailableChange} = props

  const data = useStaticQuery(
    graphql`
      query {
        allStrapiCarrousels: allStrapiCarrousel(sort: { fields: id }) {
          edges {
            node {
              id
              description
              url
              imagen {
                localFile {
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  const [dataCarousel, setDataCarousel] = useState(
    data.allStrapiCarrousels.edges
  )

  // const [dataCarousel, setDataCarousel] = useState(
  //   []
  // )

  //modificando en sección principal si existe data en el carousel
  dataCarousel?.length > 0 ? onDataAvailableChange(true) : onDataAvailableChange(false)

  const handleSlideChange = (index) => {
    setIndex(index)
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
    {
      dataCarousel?.length > 0 && (
    <div
      style={{
        width: "100%", // Ancho al 100% del contenedor padre
        maxWidth: "700px", // Ancho máximo para evitar que se expanda demasiado
        marginBottom: "20px",
        padding: "20px",
        background: "white",
        borderRadius: "6px",
        boxShadow: "0px 0px 5px 0px rgba(189,189,189,1)",
        position: "relative",
        // transform: "rotate(-1deg)",
        marginTop:"35px"
      }}
    >
       {/* <img
       style={{
        position: 'absolute',
        width: '60px',
        height: '60px',
        zIndex: '999',
        top: '-30px',
        right: '-25px',
        transform: 'rotate(20deg)',
        filter: "grayscale(1)"
       }}
          src={ImageChinche}
          alt="profile-upload"
        /> */}
      <Carousel
        navButtonsAlwaysVisible
        onChange={handleSlideChange}
        timeout={2000} // Duración de la transición en milisegundos
        duration={2000}
        interval={10000}
        animation="fade" // Tipo de animación
        index={index}
        height={"350px"}
        style={{
          width: "100%",
        }}
        autoPlay={true}
        navButtonsProps={{
          style: {
            // Estilos para los botones de navegación individuales
            backgroundColor: "#47C0B6", // Color de fondo azul
            color: "white", // Color del texto blanco
            borderRadius: "50%", // Borde redondeado para que el botón sea circular
            width: "40px", // Ancho del botón
            height: "40px", // Altura del botón
            margin: "0 10px", // Margen entre los botones
            boxShadow: "4px 3px 8px grey"
          }
        }}
      >
        {dataCarousel?.map((item, i) => (
          <Item
            key={i}
            item={item}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isHovered={isHovered}
          />
        ))}
      </Carousel>
    </div>
    )
    }
    </>
  )
}

function Item(props) {
  const { item } = props

    const handleNavigate = () => {
      if(item.node.url){
        window.open(item.node.url, "_blank")
      }
    }

  return (
    <div
      onClick={handleNavigate}
      style={{ position: "relative", background: "white", cursor:'pointer' }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <img
        style={{
          width: "100%",
          height: '350px',
          opacity: props.isHovered ? 0.9 : 1,
          transition: "opacity 0.5s ease-in-out",
          objectFit: "cover",
          boxShadow: "0px 0px 16px 0px rgba(66,66,66,1)",
        }}
        src={item.node.imagen.localFile.url}
        alt={item.node.description}
      />

      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          background: "#585858",
          color: "white",
          padding: "20px",
          opacity: props.isHovered ? 1 : 0.8,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        {item.node.description}{item.node.url &&<span style={{fontWeight:"bold", color:"yellow"}}> VER MÁS...</span>}
      </div>
    </div>
  )
}

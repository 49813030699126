import React, {useEffect} from 'react'
import { navigate } from "gatsby"
import { initAxiosInterceptors } from 'utils/axiosConfig'
import { useDialog } from 'context/DialogContext'
import { useLoading } from 'context/LoadingContext'
import Axios from 'axios'
import BudgetPersonalAccidentsForm from './BudgetPersonalAccidentsForm'


export default function BudgetPersonalAccidentsPublic({ registerDataToHubsPot }) {
    const dialog = useDialog();
    const loading = useLoading();
    useEffect(() =>{
        initAxiosInterceptors(dialog,loading)
    },[])

    async function onGenerate(params, indApin,profesion) {

           const valores = { ...params ,p_indapin: indApin ?? null, p_profesion:profesion?? null  }

          try{
            const response = await Axios.post('/dbo/budgets/generate_budget_persons_public', valores)
            await registerDataToHubsPot({
                ...params,
                p_budget_id: response?.data?.p_budget_id
            })
            navigate(`/cotizar_valida?id=${response.data.p_budget_id}`)
        }catch(error){
            console.error(error)
        }
    }

    return (
        <BudgetPersonalAccidentsForm onGenerate={onGenerate} publicForm  />
    )
}

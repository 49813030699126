import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import { graphql, useStaticQuery } from "gatsby"
import axios from "axios"
import TabSimpleFormLanding from "components/Core/Tabs/TabSimpleFormLanding"
import BudgetVehiclePublic from "Portal/Views/Budget/BudgetVehicle/BudgetVehiclePublic"
import BudgetPersonsPublic from "Portal/Views/Budget/BudgetPersons/BudgetPersonsPublic"
import BudgetTravelPublic from "Portal/Views/Budget/BudgetTravel/BudgetTravelPublic"
import BudgetPatrimonialPublic from "Portal/Views/Budget/BudgetPatrimonial/BudgetPatrimonialPublic"
import BudgetPetsPublic from "Portal/Views/Budget/BudgetPets/initPublic/BudgetPetsPublic"
import BudgetPersonalAccidentsPublic from 'Portal/Views/Budget/BudgetPersonalAccidents/BudgetPersonalAccidentsPublic'
import styles from "./pestanasCotizadoresStyle"
import BudgetHomePublic from "Portal/Views/Budget/BudgetHome/BudgetHomePublic"
import { activeEnv } from "../../../utils/utils"

const PRODUCTS_TYPES = {
  'COT_AUTO' : 'Auto',
  'COT_SALUD' : 'Salud',
  'COT_HOGAR' : 'Hogar',
  'COT_MASC' : 'Mascotas',
  'COT_VIAJE' : 'Viaje',
  'COT_PYME' : 'Pyme',
  'COT_APIN' : 'Accidentes Personales'
}

export default function ScrollableTabsButtonForce(props) {
  const { updateTitle } = props

  const NewP = styled("p")(({ theme }) => ({
    color: theme.palette.primary.main,
    opacity: "0.9",
    fontSize: "17px",
    margin: 0,
    textAlign: "center",
  }))

  const [value, setValue] = React.useState(0)
  const [currentProduct, setCurrentProduct] = useState(1)

  const handleChange = (event, newValue) => {
    setCurrentProduct(cotizadoresObject[newValue+1])
    setValue(newValue)
    updateTitle && updateTitle(tabs[newValue], newValue)
  }

  const data = useStaticQuery(
    graphql`
      query {
        allStrapiCotizadores: allStrapiCotizadore(sort: { fields: orden }) {
          edges {
            node {
              id
              orden
              codigo_cotizador
              nombre_cotizador
              texto_principal {
                data {
                  texto_principal
                }
              }
              texto_secundario {
                data {
                  texto_secundario
                }
              }
              imagen_fondo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
              imagen_tablet {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
              imagen_movil {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const registerDataToHubsPot = async (data) => {
    let contactCreatedDate = new Date()
    let formattedContactCreatedDate = `${contactCreatedDate.getDate()}/${contactCreatedDate.getMonth() + 1}/${contactCreatedDate.getFullYear()}`

    if (
      process.env.GATSBY_INSURANCE_COMPANY === 'OCEANICA' &&
      activeEnv === 'production'
    ) {
      try {

        const {
          p_applicant_name,
          p_applicant_phone_number,
          p_applicant_email
        } = JSON.parse(data.p_json_info)

        await axios.post(`${process.env.GATSBY_API_URL}/asg-api/hubspot`,
          {
            p_applicant_name,
            p_applicant_phone_number,
            p_applicant_email,
            p_applicant_email_to_hubspot: "ventas@ocenicadesegurosca.hs-inbox.com",
            p_applicant_company: process.env.GATSBY_INSURANCE_COMPANY,
            p_applicant_website: process.env.GATSBY_API_URL,
            p_applicant_products: currentProduct.toUpperCase(),
            p_applicant_budget_status: "COTIZA",
            p_applicant_nro_budget: data?.p_budget_id,
            p_applicant_status_date: formattedContactCreatedDate,
            p_id_mensaje: "1621"
          }
        );

      } catch (error) {
        console.error(error)
      }
    }
  }

  const tabs = []
  const cotizadores = data.allStrapiCotizadores.edges

  const cotizadoresObject = cotizadores.reduce((acc, node) => {
    acc[node.node.orden] = PRODUCTS_TYPES[node.node.codigo_cotizador] || '';
    return acc;
  }, {});

  const arrayReturn = cotizadores.map((node) => {
    tabs.push({
      id: node.node.id,
      titulo: node.node.nombre_cotizador,
      value: node.node.orden,
      texto_principal: node.node.texto_principal.data.texto_principal,
      texto_secundario: node.node.texto_secundario.data.texto_secundario,
      imagen:
        node.node.imagen_fondo.localFile.childImageSharp.gatsbyImageData.images
          .fallback.src,
      imagen_movil:
        node.node.imagen_movil.localFile.childImageSharp.gatsbyImageData.images
          .fallback.src,
      imagen_tablet:
        node.node.imagen_tablet.localFile.childImageSharp.gatsbyImageData.images
          .fallback.src,
      component:
        node.node.codigo_cotizador === "COT_AUTO" ? (
          <BudgetVehiclePublic registerDataToHubsPot={registerDataToHubsPot} />
        ) : node.node.codigo_cotizador === "COT_SALUD" ? (
          <BudgetPersonsPublic registerDataToHubsPot={registerDataToHubsPot} />
        ) : node.node.codigo_cotizador === "COT_HOGAR" ? (
          <BudgetHomePublic registerDataToHubsPot={registerDataToHubsPot} />
        ) : node.node.codigo_cotizador === "COT_MASC" ? (
          <BudgetPetsPublic registerDataToHubsPot={registerDataToHubsPot} />
        ) : node.node.codigo_cotizador === "COT_VIAJE" ? (
          <BudgetTravelPublic registerDataToHubsPot={registerDataToHubsPot} />
        ) : node.node.codigo_cotizador === "COT_PYME" ? (
          <BudgetPatrimonialPublic registerDataToHubsPot={registerDataToHubsPot} />
        ) : node.node.codigo_cotizador === "COT_APIN" ? (
          <BudgetPersonalAccidentsPublic registerDataToHubsPot={registerDataToHubsPot} />
        ) :null,
    })
  })

  useEffect(() => {
    setCurrentProduct(cotizadoresObject[1])
  }, [])

  return (
    <div style={{ ...styles.container }}>
      <NewP>Póliza de forma rápida y sencilla</NewP>

      <TabSimpleFormLanding
        value={value}
        onChange={handleChange}
        variant={tabs.length > 2 ? "scrollable" : "standard"}
        centered={tabs.length > 2 ? false : true}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        data={tabs}
      />
    </div>
  )
}
